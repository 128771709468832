import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from 'react-icons/bi';
import { GlobalDataContext } from "../../context/context";

const FeaturedCourse = (props) => {
		const { rpdata } = useContext(GlobalDataContext);
        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="course-area pd-top-110 pd-bottom-90 go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-6">
			        <div className="section-title text-center">
			          <h2 className="sub-title double-line">OUR Services</h2>
			        </div>
			      </div>
			    </div>
			    <div className="row">
					{
						rpdata?.dbServices?.slice(0,6).map((item, index) => {
							return <div className="col-lg-4 col-md-6" key={index}>
							<div className="single-course-inner style-two">
							  <div className="emt-thumb-icon">
								<img src={rpdata?.dbPrincipal?.logo}/>
							  </div>
							  <div className="thumb">
								<img src={item.description[0].img} alt="img" className='img-card-services-home'/>
							  </div>
							  <div className="details">
								<div className="emt-course-meta border-0">
								  <div className="row">
								  <Link to="/services">
									<div className="col-10">
									  <h6>{item.name}</h6>
									  <h6 className='text-services'>{
									  item.description[0].text.substring(0, 80) + '...'
									  }</h6>
									</div>
									<div className="col-2 text-right">
									  <Link className="arrow-right" to="/services"><i className="fa fa-arrow-right bg-maincolor p-2 rounded-circle text-white"></i></Link>
									</div>
									</Link>
								  </div>
								</div>
							  </div>
							</div>
						  </div>
						}
						)
					}
			      
			    </div>
			  </div>
			</div>

        }

export default FeaturedCourse