import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { GlobalDataContext } from "../../context/context";
import {MdConstruction} from 'react-icons/md';
import {TbPaint} from 'react-icons/tb';
import {FaHardHat} from 'react-icons/fa';
import {GrStackOverflow} from 'react-icons/gr';


const IntroV2 = (props) => {
	const { rpdata } = useContext(GlobalDataContext);
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="intro-area intro-area--top">
			  <div className="container">
			    <div className="intro-area-inner-2">
			      {/* <div className="row justify-content-center">
			        <div className="col-lg-6">
			          <div className="section-title text-center">
			            <h4 className="sub-title double-line">{rpdata?.dbSlogan?.[0].slogan}</h4>
			          </div>
			        </div>
			      </div> */}
			      <div className="intro-footer bg-base">
			        <div className="row">
			          <div className="col-md-3">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
			                  <TbPaint className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">Painting Services</h5>
			                </div>
			              </div>
			            </div>
			          </div>
					  <div className="col-md-3">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
			                  <FaHardHat className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">Kitchen Remodeling</h5>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="col-md-3">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
							<GrStackOverflow className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">Ceramic</h5>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="col-md-3">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
							<FaHardHat className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">Bathroom Remodeling</h5>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }

export default IntroV2